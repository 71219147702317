
import React from 'react';

function ViewPolicy(props) {
    return (
        <div>
            <p>View Policy</p>
        </div>
    );
}

export default ViewPolicy;