import React from 'react'

function Thanks() {
  return (
    <div>
      <p>Thanks</p>
    </div>
  )
}

export default Thanks
